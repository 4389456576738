import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import cryptoJenny from "./Asserts/CryptoJenny.png";
import cryptoJake from "./Asserts/CryptoJake.jpeg"; // Varsayalım ki bu karakterin resmi de var

function App() {
  const [inputText, setInputText] = useState("");
  const [audioSrc, setAudioSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState("jenny"); // Default olarak Jenny seçili
  const textareaRef = useRef(null);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
    autoResizeTextarea();
  };

  const handleCharacterChange = (e) => {
    setSelectedCharacter(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // https://api.criptojenny.ceremeet.com/generate-audio
    //http://localhost:5001/generate-audio
    axios.post('https://api.criptojenny.ceremeet.com/generate-audio', { text: inputText, character: selectedCharacter }, { responseType: 'blob' }) 
      .then((response) => {
        const audioBlob = new Blob([response.data], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioSrc(audioUrl);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Audio istek hatası:", error);
        setLoading(false);
      });
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Önce yüksekliği sıfırlayarak küçült
      textarea.style.height = `${textarea.scrollHeight}px`; // Yeni yüksekliği ayarla
    }
  };

  useEffect(() => {
    autoResizeTextarea();
  }, [inputText]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-md bg-white rounded shadow-md p-6">
        {/* Karakter Seçim Alanı */}
        <div className="flex justify-center mb-6">
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="character"
              value="jenny"
              checked={selectedCharacter === "jenny"}
              onChange={handleCharacterChange}
              className="form-radio"
            />
            <img
              src={cryptoJenny}
              alt="Crypto Jenny"
              className="h-24"
            />
            <span>Crypto Jenny</span>
          </label>
          <label className="flex items-center space-x-2 ml-4">
            <input
              type="radio"
              name="character"
              value="jake"
              checked={selectedCharacter === "jake"}
              onChange={handleCharacterChange}
              className="form-radio"
            />
            <img
              src={cryptoJake}
              alt="Crypto Jake"
              className="h-24"
            />
            <span>Crypto Jake</span>
          </label>
        </div>
        <h1 className="text-4xl text-center font-bold mb-4">Crypto Jenny & Jake</h1>
        <p className="text-center mb-4">
          Lütfen okumamı istediğiniz metni giriniz:
        </p>
        <form onSubmit={handleSubmit}>
          <textarea
            ref={textareaRef}
            value={inputText}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-4 resize-none overflow-hidden"
            placeholder="Metin Giriniz"
            rows={4}
          />
          <button
            type="submit"
            className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center justify-center"
          >
            {loading ? (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.978 7.978 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              "Gönder"
            )}
          </button>
        </form>
        {audioSrc && !loading && (
          <audio controls className="w-full p-2 mt-4 border border-gray-300 rounded">
            <source src={audioSrc} type="audio/wav" />
            Tarayıcınız bu ses dosyasını desteklemiyor.
          </audio>
        )}
        {loading && (
          <p className="text-center mt-4 text-gray-600">Ses dosyanız oluşturuluyor, lütfen bekleyiniz...</p>
        )}
      </div>
    </div>
  );
}

export default App;